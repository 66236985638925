import { Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, Inject, Output, EventEmitter } from '@angular/core';

import { Store } from '@ngrx/store';
import { Globals } from 'src/app/common/globals';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { AppState } from 'src/app/app.states';
import { TranslateService } from '@ngx-translate/core';
import { HoneycombCustom } from 'src/app/services/honeycomb-api/honeycomb-custom-api';
import roundCheck from '@iconify/icons-ic/round-check';
import { zoomScrollable } from 'src/app/common/functions';
import twotoneEdit from '@iconify/icons-ic/twotone-edit';
import roundContentPaste from '@iconify/icons-ic/round-content-paste';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class MessageItemComponent implements OnInit, AfterViewInit {

  tenantHash: string = null;
  userID: number = null;
  roundCheck = roundCheck;
  twotoneEdit = twotoneEdit;
  roundContentPaste = roundContentPaste;

  selectedStepper = 0;
  selectedActivity: number;

  @Input() message: Honeycomb.Tenant.Tasker.IService.Model.TaskMessage;

  @Input() isLast: boolean;

  @Input() inEdit = false;

  @Output() editMessage: EventEmitter<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage> 
                          = new EventEmitter<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>();

  constructor(
    private store: Store<AppState>,
    private globals: Globals,
    private trans: TranslateService,
    private sanitizer: DomSanitizer,
    @Inject('FileControllerCustom') private fileCustomController: HoneycombCustom.Tenant.Tasker.IService.Controller.FileControllerCustom,
    @Inject('FileController') private fileController: Honeycomb.Tenant.Tasker.IService.Controller.FileController,
    ) {
    this.store.select(s => s.auth.userID).subscribe(s => this.userID = s);
    this.store.select(s => s.auth.tenantHash).subscribe(s => this.tenantHash = s);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  public isFromMe(message: Honeycomb.Tenant.Tasker.IService.Model.TaskMessage): boolean {
    return message.userID === this.userID;
  }

  getImageUrl(imgGuid: string, maxWidth: number = 50, maxHeight: number = 50): string {
    return [this.globals.GetUrlPrefix(), 'api/DocumentStorage/TaskerFile/GetImage', imgGuid].join('/')
    + '?TenantHash=' + this.tenantHash + '&maxWidth=' + maxWidth + '&maxHeight=' + maxHeight;
  }

  async download(message: Honeycomb.Tenant.Tasker.IService.Model.TaskMessage) {

    const fileMetadata = await this.fileController.GetMetadata(message.documentUID).toPromise();
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    link.href =  `${this.globals.GetUrlPrefix()}/api/TenantTasker/file/taskattachment/${message.documentUID}/${fileMetadata.fileName}?TenantHash=${this.tenantHash}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  zoomAttachmentImage(photoUID: any) {
    zoomScrollable(this.getImageUrl(photoUID, 1920, 1080), this.trans.instant('tasker.common.close'),
                   this.trans.instant('tasker.common.delete'));
  }

  edit(message: Honeycomb.Tenant.Tasker.IService.Model.TaskMessage) {
    // console.log(message, "EDIT");
    this.editMessage.emit(message);
  }

  sanitizeContent(content: string): SafeHtml | null {
    if (content == null) 
    {
      return null;
    }
    // Replace spaces with non-breaking spaces and newline characters with <br>
    const htmlContent = content
                        .replace(/ /g, '&nbsp;')
                        .replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }
}
