<div style="flex: 1 0 100%; flex-direction: row; display: flex;"
  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" 
  fxLayout.xs="row" fxLayoutAlign.xs="center start" fxLayoutGap.xs="0px"
  (click)="openDetail(store.locationID)">
  <div class="line-content">
    <div fxLayout="column" fxFlex="1 0 80%">
      <div style="flex: 1 1 0%; white-space: nowrap; display: flex"><b>{{ store.name }}</b>
        <ng-container *ngxPermissionsOnly="uiRole('risk')">
          <span *ngIf="!!getRiskValue(store)" [class]="'risk ' + getRiskValue(store)">{{ getRiskValue(store) }}</span>
        </ng-container>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="task-item-infoline">
        <div style="flex: 1 1 0%; display: flex">{{ getTxtAddress(store) }} 
          &nbsp;&nbsp; <div *ngIf="store.users && store.users.length > 0">
            <span *ngFor="let contact of store.users" class="contactName"><mat-icon style="transform: scale(0.6);" [icIcon]="twotonePersonPin"></mat-icon> {{ contact.name }}</span> &nbsp;
          </div>
        </div>
      </div>
      <a fxShow.xs="true" fxHide.gt-xs="true" *ngIf="phoneNrValid(store.phone)" href="tel:{{store.phone}}" (click)="phoneNrClicker($event)" style="display: flex; flex: 0 1 auto;">
        <span [icIcon]="twotonePhoneForwarded" class="phone-icon"></span>
        <span class="task-item-date" [title]="store.phone">{{ store.phone }}</span>
      </a>
      <ng-container *ngIf="!phoneNrValid(store.phone)">
        <span fxShow.xs="true" fxHide.gt-xs="true" class="task-item-date" [title]="store.phone">
           {{ store.phone }}</span>
      </ng-container>
    </div>
    <mat-icon fxFlex="0 0 30px" fxFlexAlign="center" fxShow.xs="true" fxHide.gt-xs="true" [icIcon]="outlineKeyboardArrowRight" class="row-arrow"></mat-icon>
  </div>
  <ng-container *ngxPermissionsOnly="uiRole('additional-info')">
    <div *ngIf="storeAdditionalInfo" fxLayout="column" >
      <div *ngFor="let nv of storeAdditionalInfo.infoValues" fxLayout="column">
        <div fxLayout="row"  fxLayoutGap="10px" class="task-item-infoline">
          <span>{{nv.name}}</span>
          <span>{{nv.value}}</span>
        </div>
      </div>
    </div>
  </ng-container>
  <div fxHide.xs="true" fxShow.gt-xs="true" fxLayoutAlign="end center" fxFlex="1 0 100px" fxFlex.xs="1 0 auto">
    <a *ngIf="phoneNrValid(store.phone)" href="tel:{{store.phone}}" (click)="phoneNrClicker($event)" style="display: flex; flex: 0 1 auto;">
      <span [icIcon]="twotonePhoneForwarded" class="phone-icon"></span>
      <span class="task-item-date" [title]="store.phone">{{ store.phone }}</span>
    </a>
    <ng-container *ngIf="!phoneNrValid(store.phone)">
      <span class="task-item-date" [title]="store.phone">
         {{ store.phone }}</span>
    </ng-container>
    <mat-icon [icIcon]="outlineKeyboardArrowRight" class="row-arrow"></mat-icon>
  </div>
</div>